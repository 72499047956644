import { Vue } from "vue-class-component";
import { AssetsInterface, FolderDataInterface } from "@/types";
import { assetsModule } from "@/store";

export default class RoutingUtilityMixin extends Vue {
  protected get getAlbums(): FolderDataInterface[] {
    return assetsModule.getAlbums;
  }

  private findAlbumById(
    albums: FolderDataInterface[],
    id: string | null
  ): FolderDataInterface | undefined {
    return albums.find((album) => album.id === id);
  }

  protected getAlbumPathByKey(
    asset: AssetsInterface | undefined,
    fields: Array<keyof FolderDataInterface>
  ): Array<{ [key: string]: string }> {
    if (!asset) return [];

    const albums = this.getAlbums;
    const initialAlbumId = asset.albumId || null;
    const foundFields: Array<{ [key: string]: string }> = [];
    let currentAlbum = this.findAlbumById(albums, initialAlbumId);

    while (currentAlbum) {
      if (Array.isArray(fields)) {
        const fieldObject: { [key: string]: string } = {};
        fields.forEach((f) => {
          if (currentAlbum && currentAlbum[f]) {
            fieldObject[f as string] = currentAlbum[f] as string;
          }
        });
        foundFields.unshift(fieldObject);
      }

      currentAlbum = this.findAlbumById(
        albums,
        currentAlbum.parentId as string
      );
    }

    return foundFields;
  }

  protected getFullAlbumsRouteByAlbumId(
    albumId: string | null,
    output: "array" | "string" = "array"
  ): string[] | string {
    const albums = this.getAlbums;
    const foundSlugs: string[] = [];

    let currentAlbum = this.findAlbumById(albums, albumId);

    while (currentAlbum) {
      foundSlugs.unshift(currentAlbum["slug"] as string);
      currentAlbum = this.findAlbumById(
        albums,
        currentAlbum.parentId as string
      );
    }

    if (output === "string") {
      return `/albums/${foundSlugs.join("/")}`;
    }

    return foundSlugs;
  }

  protected getAlbumSlugPath(asset: AssetsInterface | undefined): string {
    const albumsPath = this.getAlbumPathByKey(asset, ["slug"])?.map(
      (o) => o.slug
    );

    if (!albumsPath.length) {
      return "";
    }

    return `/albums/${albumsPath.join("/")}`;
  }

  protected getFullAssetRouteAsString(
    asset: AssetsInterface | undefined
  ): string {
    return `${this.getAlbumSlugPath(asset)}/assets/${asset?.slug}`;
  }

  protected getFullAssetRouteAsArray(
    asset: AssetsInterface | undefined
  ): string[] {
    if (!asset) return [];

    const albumNames =
      this.getAlbumPathByKey(asset, ["name"])?.map((o) => o.name) || [];

    return [...albumNames, asset.title];
  }

  protected getFullAssetRoute(
    asset: AssetsInterface | undefined,
    fields: Array<keyof FolderDataInterface>
  ): Array<{ [key: string]: string }> {
    if (!asset) return [];

    const albumPath = this.getAlbumPathByKey(asset, fields);
    const assetInfo = { name: asset.title, slug: asset.slug };
    albumPath.push(assetInfo);
    return albumPath;
  }

  protected get getAlbumPathArray(): string[] | string {
    const albumPath: string[] | string = this.$route.params.albumPath || [];
    const assetsIndex = albumPath.indexOf("assets");
    const cutAlbumPath =
      assetsIndex !== -1 ? albumPath.slice(0, assetsIndex) : albumPath;

    return cutAlbumPath;
  }

  protected isRoutePathValid(
    routePath: string,
    asset: AssetsInterface | undefined
  ): boolean {
    const fullAssetPath = this.getFullAssetRouteAsString(asset);
    return routePath.trim() === fullAssetPath.trim();
  }

  protected redirectToRoute(route: string): Promise<any> {
    if (route !== this.$route.path) {
      return this.$router.push({
        path: route,
        query: {
          redirect_to: this.$route.query.redirect_to,
        },
      });
    } else {
      return Promise.resolve();
    }
  }

  protected redirectToAlbum(albumPath: string[] | string): void {
    const path =
      typeof albumPath === "string"
        ? `/albums/${albumPath}`
        : `/albums/${albumPath.join("/")}`;

    this.$router.push({ path: path });
  }
}
