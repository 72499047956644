
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import { AssetsInterface, FoldersWithAssetsInterface } from "@/types";
import { assetsModule } from "@/store";
import { useMq } from "vue3-mq";

@Options({
  name: "Breadcrumbs",
})
export default class Breadcrumbs extends mixins(
  RoutingUtilityMixin,
  AlbumAndAssetManagementMixin
) {
  private mq = useMq();

  protected get getAssetData(): AssetsInterface | undefined {
    return assetsModule.getAssetsData.find(
      (a) => a.slug === this.$route.params.assetSlug
    );
  }

  protected get assetPath(): Array<{ [key: string]: string }> | [] {
    return this.getFullAssetRoute(this.getAssetData, ['name', 'slug']) || [];
  }

  protected get getDefaultAlbums(): FoldersWithAssetsInterface[] {
    const isDesktop = ["lg", "xl", "xxl"].includes(this.mq.current);

    if (isDesktop) {
      return assetsModule.getFilteredAlbums;
    }

    return assetsModule.getFoldersWithAssets;
  }

  protected get isRouteValid(): boolean {
    const { albumPath, assetSlug } = this.$route.params;
    const asset = this.findAssetBySlug(
      this.getDefaultAlbums,
      assetSlug as string
    );

    return this.isRoutePathValid(this.$route.path, asset);
  }

  protected generatePath(index: number): string {
    const basePath = "/albums";
    const pathSegments = this.assetPath
      .slice(0, index + 1)
      .map((item) => item.slug);
    return `${basePath}/${pathSegments.join("/")}`;
  }

  protected isNotLastIndex(index: number): boolean {
    return index < this.assetPath.length - 1;
  }
}
